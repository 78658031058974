import React from "react";
import ReactPlayer from "react-player";
import { Grid, Typography } from "@material-ui/core";

const NonModalVideoPlayer = (props) => {
  return (
    <Grid>
      <Grid container>
        <h2 className="image-title">{props.name}</h2>
      </Grid>
      <Grid container>
        <ReactPlayer url={props.url} playing={false} controls={true} />
      </Grid>
      <Grid>
        <Typography>{props.note}</Typography>
      </Grid>
    </Grid>
  );
};

export default NonModalVideoPlayer;
