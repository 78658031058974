import React from "react";
import ReactPlayer from "react-player";
import { Grid, Typography } from "@material-ui/core";

const PodcastVideoPlayer = (props) => {
  return (
    <Grid container className="podcast-cont">
      <Grid className="podcast-child-cont">
        <ReactPlayer
          url={props.url}
          controls={true}
          playing={false}
          className="player-cont"
        />
        <div className="podcast-detail-cont">
          <Typography component="h2">{props.userGivenName}</Typography>
          <Typography component="p">{props.note}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default PodcastVideoPlayer;
